/* .heading {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 1rem;
  font-weight: bold;
} */
.img-holder {
  margin: auto;
  width: 150px;
  height: 150px;

  margin-top: 1rem;
}
.action-link:hover {
  background-color: #eff2f5;
}
.img {
  width: 150px;
  height: 130px;
  border-radius: 10px;
  border: 5px solid rgb(242, 237, 237);
  object-fit: cover;
}
#input[type='file'] {
  display: none;
}
#iinput[type='file'] {
  display: none;
}
.label {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
/* .image-upload {
  margin: '10px 0px';
  width: 110px;
  height: 30px;
  color: white;
  border-radius: 10px;
  background-color: black;
  text-align: center;
  cursor: pointer;
} */
