.multiSelectContainer li:hover {
  background-color: #deedf7;
  color: black;
}
.highlightOption {
  background-color: white;
  color: black;
}
.searchBox {
  background-color: '#f5f8fa';
}

.searchWrapper {
  border: none;
  padding: 5px;
}
.css-xb97g8:hover {
  background-color: #e6e6e6 !important;
}
.action-link:hover {
  background-color: #eff2f5;
}

.css-1s2u09g-control {
  background-color: #f5f8fa !important;
  border: none !important;
  min-height: 3.3rem;
}
.css-14el2xx-placeholder {
  color: #a7abbc !important;
  font-size: 1.1rem;
  font-weight: 500;
}
.css-1pahdxg-control {
  background-color: #f5f8fa !important;
  border: none !important;
  box-shadow: none !important;
  min-height: 3.3rem;
}
.css-1pahdxg-control {
  color: #a7abbc !important;
  box-shadow: none;
  font-size: 1.1rem;
  font-weight: 500;
}
/* Locations */
.css-yk16xz-control {
  border: none !important;
  background-color: #f5f8fa !important;
  min-height: 3.3rem;
}
.scroll_data {
  min-height: 450px;
  max-height: 450px;
  overflow-y: scroll;
}

.searchWrapper {
  border: none !important;
}
.css-1wa3eu0-placeholder {
  font-size: 1.1rem;
}
.multiSelectContainer li {
  padding: 10px;
  font-size: 13px !important;
}
