.customize-pagination {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
.page-item.active .page-link {
  border-radius: 50% !important;
  /* padding: 0 1.3rem !important; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
}
.page-item.active .page-link:hover {
  color: white !important;
}

.page-item .page-link {
  font-size: 1.3rem !important;
  font-weight: 400 !important;
}
.page-item:hover .page-link {
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
}
